@import './colors.scss';

@media (max-width: 980px) {

    /* Enable use of floated navbar text */
    .navbar-text.pull-right {
        float: none;
        padding-left: 5px;
        padding-right: 5px;
    }
}

.flex {
    display: flex;
}

.flex-column {
    flex-direction: column;
}

.flex-row {
    flex-direction: row;
}

.align-center {
    align-items: center;
}

.justify-center {
    justify-content: center;
}

.justify-between {
    justify-content: space-between;
}

.justify-right {
    justify-content: end;
}

.text-center {
    text-align: center !important;
    ;
}

.text-left {
    text-align: left !important;
    ;
}

.text-right,
.text-end {
    text-align: right !important;
}

.h-100 {
    height: 100%;
}

.border-1 {
    border-width: 1px;
}

.mr-2px {
    margin-right: 2px;
}

.mr-3px {
    margin-right: 3px;
}

.m-2px {
    margin: 2px;
}

.mt-1 {
    margin-top: 5px;
}

.mt-3px {
    margin-top: 3px;
}

.mt-2 {
    margin-top: 10px;
}
.mt-3 {
    margin-top: 15px;
}
.mt-4 {
    margin-top: 20px;
}

.mt-8 {
    margin-top: 40px;
}

.mt-10 {
    margin-top: 50px;
}

.mb-4 {
    margin-bottom: 20px;
}

.mb-2 {
    margin-bottom: 10px;
}

.mr-2 {
    margin-right: 10px;
}

.ml-2 {
    margin-left: 10px;
}

.text-red {
    color: var(--color-fire-red);
}


.text-green {
    color: var(--color-forest-green);
}

.login-form {
    margin-top: -100px;
    padding: 10px;
}

.form-log-msg {
    display: block;
    min-height: 20px;
    width: 100%;
}

body {
    background: var(--color-cool-gray);
}

.line-h-0 {
    line-height: 0px !important;
}

.line-h-1 {
    line-height: 5px !important;
}

.radius-50 {
    border-radius: 10px;
}

.radimg-img {
    width: 80px;
    border-radius: 10px;
    display: block;
    padding: 10px;
    background: white;
}

.radimg {
    text-align: center;
    margin-right: 10px;
    border-radius: 10px;
}

.radimg div {
    display: block;
}

.radimg [type=radio] {
    position: absolute;
    opacity: 0;
    width: 0;
    height: 0;
}

.radimg [type=radio]+img {
    cursor: pointer;
    border-radius: 10px;
    border: 2px solid silver;
}

.radimg [type=radio]:checked+img {
    border: 2px solid var(--color-royal-blue);
    border-radius: 10px;
}


.hidden {
    display: none;
}

.input-append-custom {
    display: inline-flex !important;
}

.flex-1 {
    flex: 1;
}

#verify-msg {
    text-align: center;
}

.verify-card {
    width: 450px !important;
    padding: 20px 40px;
    padding-top: 40px;
    background: white;
    border: 1px solid lightgrey;
    border-radius: 8px;
}

.profile-card {
    width: 600px !important;
    padding: 20px 40px;
    padding-top: 40px;
    background: white;
    border: 1px solid lightgrey;
    border-radius: 8px;
}

.verify-img {
    height: 100px;
    width: auto;
    margin-bottom: 25px;
    margin-top: 10px;
}

.verify-profile-img {
    width: 60px;
    height: 60px;
    border-radius: 50%;
}

#accounts {
    margin-top: 20px;
}

.account {
    border-bottom: 1px solid var(--color-medium-gray);
    margin: 0px;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
}

.account .media-body {
    margin-left: 10px;
}

.account:hover,
.account-selected {
    background: var(--color-ice-blue) !important;
}

#accounts .account:nth-child(odd) {
    background: var(--color-lightest-gray);
}

#abha-profile {
    width: 100%;
}

#profile-photo {
    width: 110px;
    height: 110px;
    max-width: 110px !important;
}

#abha-card-img {
    width: 100%;
}

.d-inline-flex {
    display: inline-flex;
}

.w-100 {
    width: 100%;
}

.h-30px {
    height: 30px;
}

.center {
    text-align: center;
}

#suggestions {
    user-select: none;
}

.select-none {
    user-select: none;
}

#create-cont {
    display: block;
    width: auto;
    height: 100px;
}

#oldAddress {
    font-weight: bold;
}

.suggestion {
    margin-top: 5px;
    margin-left: 5px;
}

#consent-table {
    font-size: 14px;
}

.card {
    border: 1px solid var(--color-medium-gray);
    background-color: white;
    border-radius: 8px;
}

.card-header {
    display: flex;
    justify-content: space-between;
    background-color: var(--color-lightest-gray);
    border-bottom: 1px solid var(--color-medium-gray);
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    padding: 2px 4px;
    padding-left: 10px;
    align-items: center;
}

.card-body {
    padding: 10px;

}

.card-footer {
    display: flex;
    padding-left: 10px;
    padding-right: 10px;
    justify-content: right;
}

.record-entry .record-diagonis {
    display: block;
}

.record-entry .record-author {
    display: block;
}

.record-entry .profile-record-view {
    text-align: right;
}

#consent-form .checkbox input[type="checkbox"] {
    margin-right: 5px;
}

/* milestone 3 */
.hi-types .fade {
    opacity: 0 !important;
    -webkit-transition: opacity 0.15s linear !important;
    -moz-transition: opacity 0.15s linear !important;
    -o-transition: opacity 0.15s linear !important;
    transition: opacity 0.15s linear !important;
}

.card-footer .pagination li {
    padding: 3px 10px;
    border-radius: 5rem;
}

.card-footer .pagination .active {
    background: var(--primary);
}

.card-footer .pagination .active * {
    color: white;
}

.hi-types .fade.in {
    opacity: 1 !important;
}

.hi-types small {
    margin-right: 3px;
}

#hi-types-info small {
    margin-top: 3px;
    margin-right: 3px;
}

#consent-form {
    max-width: 450px;
    margin: auto;
}

#consent-form select,
#consent-form input[type="date"],
#consent-form input[type="time"] {
    height: auto !important;
}

#newConsentModal .modal-body {
    min-height: 460px;
}

.line-reserve {
    display: inline-block;
    min-width: 20px;
    min-height: 20px;

}

#newConsentModal {
    width: auto !important;
}

.record-entry {
    padding-top: 10px;
    border-bottom: 1px solid var(--cui-border-color);
    padding-bottom: 10px;
}

.record-entry:last-child {
    border-bottom: 0px;
}

.record-author,
.record-date,
.record-diagonis {
    display: block;
    font-size: small;
}

.profile-record-view {
    text-align: right;
    align-self: center;
}

#record-data {
    min-height: 700px;
    overflow: scroll;
    color: #323a49;
}

#spinRecord {
    margin-left: 4px;
}

/* Dacha */

.di-keypair {
    border-bottom: 1px solid lightgrey;
    padding-bottom: 4px;
    margin-bottom: 10px;
}

.dx-keypair-key {
    font-weight: bold;
    padding-right: 10px;
}

.dx-keypair-key,
.dx-keypair-value {
    display: inline-block;
}

.ds-section {
    border-radius: 4px;
    margin-bottom: 10px;
    padding: 10px;
}

.di-custom {
    border-bottom: 1px solid lightgrey;
    padding-bottom: 4px;
    margin-bottom: 10px;
}

.di-gap {
    margin-bottom: 20px;
}

.ds-title {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 10px;
}

.di-custom-bold {
    font-weight: bold;
}

.di-custom-greyed {
    color: grey;
}

.ds-sections {
    font-family: sans-serif;
    font-size: 14px;
    display: flex;
    flex-direction: column;
    margin-right: auto;
    margin-left: auto;
    max-height: 600px;
    /* overflow-y: scroll; */
}


.placeholder {
    display: inline-block;
    min-height: 1em;
    vertical-align: middle;
    cursor: wait;
    background-color: currentcolor;
    opacity: 0.1;
}

.placeholder.btn::before {
    display: inline-block;
    content: "";
}

.placeholder-xs {
    min-height: 0.6em;
}

.placeholder-sm {
    min-height: 0.8em;
}

.placeholder-lg {
    min-height: 1.2em;
}

.placeholder-glow .placeholder {
    animation: placeholder-glow 2s ease-in-out infinite;
}

@keyframes placeholder-glow {
    50% {
        opacity: 0.2;
    }
}

.placeholder-wave {
    -webkit-mask-image: linear-gradient(130deg, #080a0c 55%, rgba(0, 0, 0, 0.8) 75%, #080a0c 95%);
    mask-image: linear-gradient(130deg, #080a0c 55%, rgba(0, 0, 0, 0.8) 75%, #080a0c 95%);
    -webkit-mask-size: 200% 100%;
    mask-size: 200% 100%;
    animation: placeholder-wave 2s linear infinite;
}

@keyframes placeholder-wave {
    100% {
        -webkit-mask-position: -200% 0%;
        mask-position: -200% 0%;
    }
}

.record-entry {
    border-bottom: 1px solid var(--color-medium-gray);
}

.patient-action-btn {
    width: 54px !important;
}
.patient-action-span {
    font-size: 12px ;
}
.gap-2 {
    gap: 10px;
}

.btn-purple{
    background-color: #7526a3 !important;
    color: white;
}

.btn-purple:hover {
    color: white;
    box-shadow: 0 8px 25px -8px rgba(#7526a3, 1);
}

.btn-pink{
    background-color: #db1486 !important;
    color: white;
}

.btn-pink:hover {
    color: white;
    box-shadow: 0 8px 25px -8px rgba(#db1486, 1);
}

.btn-orange1{
    background-color: #f2801d !important;
    color: white;
}

.btn-orange1:hover {
    color: white;
    box-shadow: 0 8px 25px -8px rgba(#f2801d, 1);
}


.btn-imm, .bg-imm{
    background-color: #7526a3 !important;
    color: white;
}

.btn-imm:hover {
    color: white;
    box-shadow: 0 8px 25px -8px rgba(#7526a3, 1);
}

.btn-wel, .bg-wel{
    background-color: #db1486 !important;
    color: white;
}

.btn-wel:hover {
    color: white;
    box-shadow: 0 8px 25px -8px rgba(#db1486, 1);
}

.btn-pre, .bg-pre{
    background-color: #b8b406 !important;
    color: white;
}

.btn-pre:hover {
    color: white;
    box-shadow: 0 8px 25px -8px rgba(#b8b406, 1);
}

.btn-opc:hover {
    color: white;
    box-shadow: 0 8px 25px -8px rgba(#f29d1d, 1);
}

.btn-opc, .bg-opc{
    background-color: #f29d1d !important;
    color: white;
}


.btn-orange3{
    background-color: #b8b406 !important;
    color: white;
}

.btn-orange3:hover {
    color: white;
    box-shadow: 0 8px 25px -8px rgba(#b8b406, 1);
}

.btn-doc:hover  {
    background-color: #32a852 !important;
    color: white;
}

.btn-doc,.bg-doc{
    background-color: #32a852 !important;
    color: white;
}


.btn-dis, .bg-dis{
    background-color: #f2801d !important;
    color: white;
}

.btn-dis:hover{
    color: white;
    box-shadow: 0 8px 25px -8px rgba(#f2801d, 1);
}

.btn-dia, .bg-dia {
    background-color: #1f98de !important;
    color: white;
}

.btn-dia:hover {
    background-color: #1f98de !important;
    color: white;
}